import React from 'react'
import PropTypes from 'prop-types'

const MinutesToRead = ( { minutes } ) => {
    return (
        <small>{minutes} min read</small>
    )
}

MinutesToRead.propTypes = {
    minutes: PropTypes.number.isRequired
}

export default MinutesToRead