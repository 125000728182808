import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import {
    FacebookShareButton,
    LinkedinShareButton,
    TwitterShareButton,
    EmailShareButton,
    FacebookIcon,
    EmailIcon,
    TwitterIcon,
    LinkedinIcon
} from 'react-share'
import PropTypes from 'prop-types'
import Config from '../../../config/siteConfig'

const styles = theme => ({
    socialLinks: {
        [theme.breakpoints.up('md')]: {
            display: 'block',
            position: 'fixed',
            paddingTop: theme.spacing.unit*10,
            paddingLeft: theme.spacing.unit*4,
        },
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            textAlign: 'center',
        },
        '& > div': {
            [theme.breakpoints.down('sm')]: {
                display: 'inline',
                padding: theme.spacing.unit
            }
        }
    },
    theLink: {
        paddingTop: theme.spacing.unit*2,
        '& div': {
            cursor: 'pointer',
            outline: 'none',
            [theme.breakpoints.down('sm')]: {
                display: 'inline'
            },
        },
    }
})

const SocialShare = ({ classes, url='', excerpt=Config.siteDescription, title=Config.siteName }) => {
    const fullUrl = Config.siteUrl + url

    return (
        <div className={classes.socialLinks}>
            <div className={classes.theLink} title='Share this on Facebook?' >
                <FacebookShareButton url={fullUrl} quote={excerpt}>
                    <FacebookIcon round={true} size={40} />
                </FacebookShareButton>
            </div>
            <div className={classes.theLink} title='Or on LinkedIn?' >
                <LinkedinShareButton url={fullUrl} title={title} description={excerpt} >
                    <LinkedinIcon round={true} size={40} />
                </LinkedinShareButton>
            </div>
            <div className={classes.theLink} title='Maybe on Twitter?' >
                <TwitterShareButton url={fullUrl} title={title} via={Config.userTwitter}>
                    <TwitterIcon round={true} size={40} />
                </TwitterShareButton>
            </div>
            <div className={classes.theLink} title='Nah, just send it as e-mail' >
                <EmailShareButton url={fullUrl} subject={title}>
                    <EmailIcon round={true} size={40} />
                </EmailShareButton>
            </div>
        </div>
    )
}

SocialShare.propTypes = {
    classes: PropTypes.object.isRequired,
    url: PropTypes.string,
    excerpt: PropTypes.string,
    title: PropTypes.string
}

export default withStyles(styles)(SocialShare)