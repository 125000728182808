import { Component } from 'react'
import ReactDOM from 'react-dom'

export default class SocialPortal extends Component {
    constructor(props) {
        super(props)
        
        this.state = {
            el: null,
            isLoad: false
        }
    }

    componentDidMount() {
        const element = document.createElement('div')
        this.setState({
            el: element,
            isLoad: true
        }, () => {
            const portalRoot = document.getElementById('social-share')
            portalRoot.appendChild(this.state.el)
        })
    }

    componentWillUnmount() {
        const portalRoot = document.getElementById('social-share')
        portalRoot.removeChild(this.state.el)
    }
    
    
    render() {
        if(this.state.isLoad){
            const { children } = this.props
            return ReactDOM.createPortal(children, this.state.el)
        }
        return null
    }
}